import React from "react";

export const About = () => (
    <div className='about'>
        <p>
            I like having my tummy rubbed.
        </p>
    </div>
);

export default About;